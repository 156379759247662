import { Component, Mixins, Vue, Watch } from "vue-property-decorator";
import AnalysisBase from "@/models/analysis/analysis-base";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import Flash, { ErrorAlert } from "@/store/common/flash";
import ShopGet from "@/store/common/shop-list/get";
import Admin from "@/store/admin/admin";
import UIDataTable from "@/components/UIDataTable.vue";
import { DEFAULT_TABLE_OPTIONS, TableOptions } from "@/api/request";
import CouponListGet from "@/store/common/coupon-list/get";
import CouponUsageListGet from "@/store/analysis/coupon-usage/get";
import { GetRequest as CouponGetRequest } from "@/api/common/coupon-list/request";
import { CouponUsageRequest } from "@/api/analysis/coupon-usage/request";

export interface CouponUsageListInputOptions {
  selectCoupon: { id: number; title: string } | null;
  selectShop: { id: string; name: string } | null;
  startDate: string;
  endDate: string;
}

@Component({
  components: {
    UlContentHeader,
    UIDatePicker,
    UIDataTable
  }
})
export default class AnalysisCoupon extends Mixins(AnalysisBase) {
  @Watch("couponUsageListInputOptions.selectShop")
  async selectShopDidChange(selectShop: { id: string; name: string } | null) {
    this.couponUsageListInputOptions.selectCoupon = null;
    if (selectShop) {
      await this.searchCouponList();
      this.isCouponDisabled = false;
    } else {
      await CouponListGet.clearResponse();
      this.isCouponDisabled = true;
    }
  }

  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "クーポン（店舗・クーポン別）";
  headingSub = "Coupon";

  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  isLoadedShopData = false;
  isLoadedCouponListData = false;
  isLoadingCouponUsageListData = false;

  // DatePickerの表示フラグ
  startDateModal = null;
  endDateModal = null;

  // couponのセレクトボックスのグレーアウト有無フラグ
  isCouponDisabled = true;

  couponUsageListInputOptions: CouponUsageListInputOptions = {
    selectCoupon: null,
    selectShop: null,
    startDate: this.initDates[0],
    endDate: this.initDates[1]
  };

  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  tableHeaders = [
    { text: "クーポンID", value: "id", sortable: false },
    { text: "クーポン名", value: "title", sortable: false },
    { text: "クーポン種別", value: "typeName", sortable: false },
    { text: "利用日", value: "usedDate", sortable: false },
    { text: "利用件数", value: "usedCount", sortable: false }
  ];

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions: TableOptions = DEFAULT_TABLE_OPTIONS;

  get startDateForView() {
    return this.replaceHyphenToSlash(this.couponUsageListInputOptions.startDate);
  }
  set startDateForView(date) {
    this.couponUsageListInputOptions.startDate = this.replaceSlashToHyphen(date);
  }

  get endDateForView() {
    return this.replaceHyphenToSlash(this.couponUsageListInputOptions.endDate);
  }
  set endDateForView(date) {
    this.couponUsageListInputOptions.endDate = this.replaceSlashToHyphen(date);
  }

  get couponItems() {
    return CouponListGet.getItems;
  }

  get shopItems() {
    return ShopGet.getItems;
  }

  get initDates() {
    const firstDate = new Date();
    const lastDate = new Date();
    return [this.stringFromDate(firstDate), this.stringFromDate(lastDate)];
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    if (this.editFocused || !this.isNeededActiveOnDashBoard) {
      return false;
    }
    await this.searchShopList();
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await ShopGet.clearResponse();
    await CouponUsageListGet.clearResponse();
  }

  /**
   * テーブルに表示するアイテムリスト（UIDataTableコンポーネントに渡す）
   */
  get tableItems() {
    return CouponUsageListGet.getItems;
  }

  /**
   * 総件数（UIDataTableコンポーネントに渡す）
   */
  get listTotalCount() {
    return CouponUsageListGet.getTotalCount;
  }

  /**
   * 店舗情報の一覧を取得する処理
   */
  async searchShopList(): Promise<boolean> {
    this.isLoadedShopData = false;
    await ShopGet.get();
    if (!ShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: ShopGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedShopData = true;
    return ShopGet.isSuccess;
  }

  /**
   * クーポンの一覧を取得する処理
   */
  async searchCouponList(): Promise<boolean> {
    this.isLoadedCouponListData = false;
    await CouponListGet.get({
      search_shop_id:
        this.couponUsageListInputOptions.selectShop != null
          ? Number(this.couponUsageListInputOptions.selectShop.id)
          : null
    } as CouponGetRequest);
    if (!CouponListGet.isSuccess) {
      await Flash.setErrorNow({
        message: CouponListGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedCouponListData = true;

    return CouponListGet.isSuccess;
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {
    // 子コンポーネントへpagingOptionsの変更が通知される
    this.tableOptions = tableOptions;
    await this.searchCouponUsageList();
  }

  /**
   * クーポン利用実績の検索処理
   */
  async searchCouponUsageList() {
    await Flash.clear();
    this.isLoadingCouponUsageListData = true;
    const request = this.createCouponUsageListGetRequest();
    await CouponUsageListGet.clearResponse();

    await CouponUsageListGet.get(request);
    if (!CouponUsageListGet.isSuccess) {
      await Flash.setErrorNow({
        message: CouponUsageListGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadingCouponUsageListData = false;
  }

  /**
   * クーポン利用実績取得のリクエストを作成する
   */
  private createCouponUsageListGetRequest(): CouponUsageRequest {
    const request = this.tableOptions as CouponUsageRequest;
    request.search_shop_id =
      this.couponUsageListInputOptions.selectShop != null
        ? Number(this.couponUsageListInputOptions.selectShop.id)
        : null;
    request.coupon_id =
      this.couponUsageListInputOptions.selectCoupon != null
        ? Number(this.couponUsageListInputOptions.selectCoupon.id)
        : null;
    request.start_date = this.startDateForView;
    request.end_date = this.endDateForView;
    request.page = this.tableOptions.page;
    request.displayCount = this.tableOptions.displayCount;
    return request;
  }
}
