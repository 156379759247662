import {
  getModule,
  Module,
  VuexModule,
  MutationAction
} from "vuex-module-decorators";
import store from "@/store";
import { SessionStorage } from "@/store/session-storage";
import { SearchItem, SearchResponse } from "@/api/push/response";
import { SearchRequest } from "@/api/push/request";
import * as PushAPI from "@/api/push";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "push/search";

/**
 * プッシュメッセージ一覧のデコレーター
 */
export class ListItem {
  constructor(private _item: SearchItem) {}
  get id() {
    return this._item.id;
  }
  get title() {
    return this._item.title;
  }
  get sendDate() {
    return this._item.sendDate;
  }
  get sendTime() {
    return this._item.sendTime;
  }
  get sendFlg() {
    let sendStatus = "予約中";
    switch (this._item.sendFlg) {
      case 1:
        sendStatus = "配信済";
        break;
      case 2:
        sendStatus = "配信処理中";
        break;
      case 3:
        sendStatus = "配信エラー";
        break;
      case 4:
        //Android配信済
        sendStatus = "配信処理中";
        break;
      case 5:
        //iOS配信済
        sendStatus = "配信処理中";
        break;
      case 10:
        //配信事前処理エラー
        sendStatus = '配信事前処理エラー';
        break;
      default:
        break;
    }
    return sendStatus;
  }
  get fixedFlg() {
    //20201221 NEW_DEV-734 C32-No.38、No.39 cyber房 start
    return this._item.fixedFlg === 0 ? "設定なし" : "設定あり";
    //20201221 NEW_DEV-734 C32-No.38、No.39 cyber房 end
  }
  // NEW_DEV-1437 cyber start
  get ignoreFollowFlg() {
    return this._item.ignoreFollowFlg;
  }
  get writeFlg() {
    return this._item.writeFlg;
  }
  get deleteFlg() {
    return this._item.deleteFlg;
  }
}

/**
 * プッシュメッセージ一覧検索API（/search-push）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Search extends VuexModule {
  // state
  searchRequest: SearchRequest = {} as SearchRequest;
  searchResponse: SearchResponse = {} as SearchResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.searchResponse);
  }

  get getMessage() {
    return this.searchResponse.message;
  }

  get getWriteFlg() {
    if (this.searchResponse.results) {
      return this.searchResponse.results.writeFlg;
    } else {
      return false;
    }
  }

  get getPushLinkFlg() {
    if (this.searchResponse.results) {
      return this.searchResponse.results.pushLinkFlg;
    } else {
      return 0;
    }
  }

  get getItems() {
    if (this.searchResponse.results) {
      // return this.searchResponse.results.items || [];
      return (this.searchResponse.results.items || []).map(
        value => new ListItem(value)
      );
    } else {
      return [];
    }
  }

  get getTotalCount() {
    if (this.searchResponse.results) {
      return this.searchResponse.results.totalCount || 0;
    } else {
      return 0;
    }
  }

  get getIsMainShop() {
    if (this.searchResponse.results) {
      return this.searchResponse.results.isMainShop;
    } else {
      return 0;
    }
  }
  // NEW_DEV-1437 cyber end

  get getSearchRequest() {
    return this.searchRequest;
  }

  // MutationActions
  @MutationAction
  async search(searchRequest: SearchRequest) {
    const searchResponse = await PushAPI.search(searchRequest);
    SessionStorage.setObject(MODULE_NAME, searchRequest);
    return {
      searchRequest,
      searchResponse
    };
  }

  @MutationAction
  async restore(initial = {} as SearchRequest) {
    const searchRequest = SessionStorage.getObject(
      MODULE_NAME,
      initial
    ) as SearchRequest;
    return {
      searchRequest
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      searchResponse: {} as SearchResponse
    };
  }
}

export default getModule(Search);
