import service from "@/api/service";
import { CouponUsageRequest } from "@/api/analysis/coupon-usage/request";
import { CouponUsageResponse } from "@/api/analysis/coupon-usage/response";

/**
 * クーポン利用実績取得APIをコールします。
 *
 * @return CouponUsageResponse
 */
export async function get(couponUsageRequest: CouponUsageRequest) {
  const response = await service.post("/analysis-coupon-usage", couponUsageRequest);
  return response.data as CouponUsageResponse;
}
