import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as CouponUsageListAPI from "@/api/analysis/coupon-usage";
import { isSuccess } from "@/api/response";
import { CouponUsageRequest } from "@/api/analysis/coupon-usage/request";
import { CouponUsageResponse } from "@/api/analysis/coupon-usage/response";

const MODULE_NAME = "analysis/coupon-usage/get";

/**
 *  クーポン利用実績取得API（/analysis-coupon-list）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getRequest: CouponUsageRequest = {} as CouponUsageRequest;
  getResponse: CouponUsageResponse = {} as CouponUsageResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getItems() {
    if (this.getResponse.results) {
      return this.getResponse.results.items || [];
    } else {
      return [];
    }
  }

  get getTotalCount() {
    if (this.getResponse.results) {
      return this.getResponse.totalCount || 0;
    } else {
      return 0;
    }
  }

  get getPageStart() {
    if (this.getResponse.results) {
      return this.getResponse.pageDisplayStart || 0;
    } else {
      return 0;
    }
  }

  get getPageEnd() {
    if (this.getResponse.results) {
      return this.getResponse.pageDisplayEnd || 0;
    } else {
      return 0;
    }
  }

  get getGetRequest() {
    return this.getRequest;
  }

  // MutationActions
  @MutationAction
  async get(getRequest: CouponUsageRequest) {
    const getResponse = await CouponUsageListAPI.get(getRequest);
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as CouponUsageResponse
    };
  }
}

export default getModule(Get);
